import React, {useEffect} from 'react';

function IdeaConcept({ updateSources }) {
    useEffect(() => {
        const sources = [
            { text: "Wikipedia: Geistige Landesverteidigung", link: "https://de.wikipedia.org/wiki/Geistige_Landesverteidigung" },
            { text: "Matthias Zender: Geistige Landesverteidigung", link: "https://www.matthiaszehnder.ch/wochenkommentar/die-schweiz-kappt-die-geistige-landesverteidigung/" },
            { text: "Wikipedia: Pro Helvetia", link: "https://de.wikipedia.org/wiki/Pro_Helvetia"}
        ];
        updateSources(sources);
    }, []);
    return (
        <section className="content">
            <div>

                <h2>Geistige Landesverteidigung: Eine politische und kulturelle Bewegung der Schweiz</h2>

                <div className="content-wrapper">
                    <div className="text">

                        <p>
                            Die Geistige Landesverteidigung ist eine politische Bewegung, die in der Schweiz
                            stattgefunden hat.
                            Während des Zweiten Weltkrieges waren die totalitären Ideologien, vor allem
                            Nationalsozialismus und
                            Faschismus, später auch der Kommunismus,
                            aus Deutschland und Italien eine Bedrohung. Als Reaktion entstand die sogenannte Geistige
                            Landesverteidigung.
                        </p>

                        <h2>Hauptziele und Ideen</h2>
                        <p>
                            Die geistige Landesverteidigung wollte die Stärkung schweizerischer Werte wie nationale
                            Unabhängigkeit und Bräuche.
                            Es sollte auch eine „Volksgemeinschaft“ durch die Abschaffung von Klassengegensätzen und die
                            Förderung kultureller Vielfalt.
                            Totalitäre Einflüsse sollten abgewehrt werden und demokratische Werte gefördert werden.
                        </p>

                        <h2>Umsetzung und Massnahmen</h2>
                        <p>
                            Es wurden verschiedene Massnahmen ergriffen, um die schweizer Werte zu stärken.
                        </p>
                        <p>Die wichtigsten sind die Kulturförderung, der Medienaufbau, die Militärische Integration und
                            die
                            Internationale Präsenz</p>
                        <p><strong>Kulturförderung:</strong> Diese Massnahme soll die nationale Identität und den
                            Zusammenhalt
                            der Bevölkerung stärken.
                            Durch Kunst, Literatur und kulturellen Projekten soll das Gefühl der Gemeinschaft gefördert
                            werden.
                            Institutionen wie zum Beispiel Pro Helvetia waren sehr wichtig, weil sie Schweizer
                            Kulturschaffende
                            fördern bekannt machen.</p>

                        <p><strong>Medienaufbau.</strong> Durch unabhängige Medienformate, wie Radiosender und digitale
                            Plattformen,
                            sollen Informationen effektiv verbreitet werden. So sollte die Bevölkerung aufgeklärt werden
                            und zu
                            verhindern,
                            dass Propaganda aus dem Ausland die Meinungen beeinflusst. </p>


                    </div>
                    <div className="image-container">
                        <img
                            src="https://static.wixstatic.com/media/532f49_82826909a19a40da8a48cd5365b3fde5~mv2.jpg/v1/fill/w_925,h_1284,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/532f49_82826909a19a40da8a48cd5365b3fde5~mv2.jpg"
                            alt="Schweizerische Landesausstellung"
                        />
                        <p className="image-caption">
                            Schweizerische Landesausstellung
                            Plastik von H. Haller. Foto: J. Gabarell. <br/>
                            Sammlung MZ. Gelaufen am 21.8.1939. <br/>
                            <a href="https://www.wollipedia.ch/post/landi-1939" target="_blank">Wollipedia - Landi 1939</a>

                        </p>
                    </div>
                </div>

                <p><strong>Militärische Integration: </strong>Soldaten sollten nicht nur auf den physischen Einsatz
                    vorbereitet werden,
                    sondern auch ein Verständnis von demokratischen Werte haben. So sollten die Soldaten die einen
                    stärkeren Willen haben,
                    diese Demokratie zu verteidigen.</p>
                <p><strong>Internationale Präsenz: </strong>Organisationen wie Pro Helvetia sowie durch Medienkanäle,
                    helfen
                    das Bild der Schweiz im Ausland zu fördern. Diese Massnahmen tragen dazu bei,
                    die schweizerische Neutralität betonen und ein positives Verständnis für die Schweiz zu schaffen.
                </p>
            </div>
        </section>
    );
}

export default IdeaConcept;
