import React, { useState, useEffect } from 'react';


function Landi1939({ updateSources }) {
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    useEffect(() => {
        const sources = [
            { text: "Historisches Lexikon der Schweiz", link: "https://hls-dhs-dss.ch" },
            { text: "Wikipedia: Geistige Landesverteidigung", link: "https://de.wikipedia.org/wiki/Geistige_Landesverteidigung" },
            { text: "Wikipedia: Landi 1939", link: "https://de.wikipedia.org/wiki/Schweizerische_Landesausstellung_1939"   }
        ];
        updateSources(sources);
    }, []);

    const toggleVideo = () => {
        setIsVideoVisible(prevState => !prevState); // Toggle den Zustand für die Video-Sichtbarkeit
    };

    return (
        <section className="content">
            <h2>Die Landi 1939</h2>
            <p>
                Die Schweizerische Landesausstellung 1939, oft als "Landi" bezeichnet, fand kurz vor Ausbruch des
                Zweiten Weltkriegs in Zürich statt.
                Sie symbolisierte die Geistige Landesverteidigung und den nationalen Zusammenhalt in einer zunehmend
                instabilen Welt.
                Sie fand an verschiedenen Plätzen und Wiesen statt. Die Leitung lag unter dem Architekten und Politiker
                Armin Meili. Es gab viele Konzerte, Theatheraufführungen und Attraktionen wie das Eidgenössische
                Trachtenfest.

            </p>
            <h3>Zutritt</h3>
            <p>
                Es gab zwei Haupt- und drei Nebeneingänge. In Wollishofen war der Haupteingang beim Bahnhof. Von 8 bis
                19 Uhr konnte man für 2 Franken einen Erwachsenen Eintritt kaufen, für Kinder 1 Franken. Die Dauerkarte
                kostete 32
                Franken. Insgesamt wurden 10'507'735 Eintritte verkauft.
            </p>

            <div className="content-wrapper">
                <div className="text">
                    <h3>Elemente der Ausstellung</h3>
                    <p>
                        Die Hauptstandorte der Landi befanden sich an der Landiwiese am linken Seeufer in Wollishofen
                        und am Zürichhorn auf der gegenüberliegenden Seite des Sees. Letzteres war besonders bekannt
                        für das Landidörfli, das mit Häusern in den traditionellen Baustilen verschiedener Kantone
                        einen beliebten Anziehungspunkt bildete. Insgesamt boten 25 Gaststätten rund 15.000 Sitzplätze.

                        Am linken Seeufer in Wollishofen wurde die moderne Schweiz in ihrer ganzen Vielfalt
                        präsentiert, mit einem klaren Fokus auf neue Architektur, Industrie, Gewerbe und
                        technische Innovationen. Zu den präsentierten Themen gehörten unter anderem Uhren,
                        Tourismus, Verkehr, Bahnen, Post und Telefon, Mode, Ernährung, Elektrizität, Maschinenbau,
                        Aluminium, Gummi, Chemie, Finanzen und Holz. Besonders hervorzuheben war die «Höhenstrasse»,
                        die ganz im Zeichen von «Heimat und Volk» stand. Eines der bekanntesten Gebäude war das
                        «Bierhus» des Thuner Architekten Arnold Itten, das mit mehreren Sälen und Terrassen Platz
                        für 1400 Personen bot und eine eigene Metzgerei beherbergte.

                        Etwas enger gefasst waren die Themen Bauen, Gas und Wasser, Wohnen, Gesundheit, Sport, Blumen,
                        Bildung, das grafische Gewerbe, Theater, Literatur und Kunst, die ebenfalls in eigenen
                        Bereichen präsentiert wurden. Ein weiteres Highlight war das Nestlé-Kinderparadies, wo die
                        damals 20-jährige Trudi Gerster den Kindern Märchen erzählte. In der Schulabteilung sorgte Emma
                        Eichenberger, die Vizepräsidentin und spätere Zentralpräsidentin des Schweizerischen
                        Lehrerinnenvereins, für die Organisation des Unterrichts und des reibungslosen Ablaufs der
                        verschiedenen Lektionen.

                        Auf der rechten Seite des Sees in Riesbach befand sich die Festhalle, deren Dach geöffnet werden
                        konnte und die mit einer Fläche von 7500 m² beeindruckte. Im Landidörfli waren zwölf Gebäude aus
                        verschiedenen Kantonen zu finden, die eine konservative und bäuerliche Schweiz darstellten. Die
                        Themen, die dort behandelt wurden, umfassten unter anderem Landwirtschaft, Kulturtechnik, Markt
                        und Propaganda, Pflanzenbau, Mosterei und Weinbau, Gemüseanbau, Tierzucht, Milchwirtschaft und
                        Käseherstellung, landwirtschaftliche Maschinen, Jagd und Fischerei, Vogelschutz sowie Trachten
                        und Heimatwerk.
                    </p>
                </div>
                <div className="image-container">
                    <img
                        src="//upload.wikimedia.org/wikipedia/commons/thumb/5/50/PTT-Archiv_Vers-033_A_0055_Systematik_des_Aufbaus_der_Schweizerischen_Landesausstellung_1939.tif/lossy-page1-800px-PTT-Archiv_Vers-033_A_0055_Systematik_des_Aufbaus_der_Schweizerischen_Landesausstellung_1939.tif.jpg"
                        alt="Aufbau der Landi 1939"
                    />
                    <p className="image-caption">
                        Systematik des Aufbaus der Schweizerischen Landesausstellung 1939
                        <br/>
                        Von <a href="https://en.wikipedia.org/wiki/de:PTT-Archiv" target="_blank">PTT-Archiv</a>,
                        <a href="https://en.wikipedia.org/wiki/de:Liste_der_Kulturg%C3%BCter_in_K%C3%B6niz"
                           target="_blank">Liste der Kulturgüter in Köniz</a>,
                        Ort: <a href="https://en.wikipedia.org/wiki/de:K%C3%B6niz" target="_blank">Köniz</a>,
                        Koordinaten: 46°55′26,69″ N, 7°24′49,64″ O,
                        <a href="http://www.mfk.ch/pttarchiv" target="_blank">PTT-Archiv auf www.mfk.ch</a>.
                        Normdatei: <a href="https://www.wikidata.org/wiki/Q16964584" target="_blank">Q16964584</a>,
                        <a href="https://viaf.org/viaf/125005137" target="_blank">VIAF: 125005137</a>,
                        <a href="https://isni.org/isni/0000000106572980" target="_blank">ISNI: 0000000106572980</a>,
                        <a href="https://d-nb.info/gnd/1086481100" target="_blank">GND: 1086481100</a>,
                        Gemeinfrei: <a href="https://commons.wikimedia.org/w/index.php?curid=76504776"
                                       target="_blank">Link</a>.
                    </p>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="image-container">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Landi_39_87688-C.jpg/1920px-Landi_39_87688-C.jpg"
                        alt="Knabe mit Pferd"
                    />
                    <p className="image-caption">
                        Knabe mit Pferd
                        <br/>
                        Von Bildnachweis: Baugeschichtliches ArchivDatierung: 1939Lizenz: Creative commons BY SA 4.0
                        - <a
                        href="https://creativecommons.org/licenses/by-sa/4.0"
                        title="Creative Commons Attribution-Share Alike 4.0">CC BY-SA 4.0</a>, <a
                        href="https://commons.wikimedia.org/w/index.php?curid=74885110">Link</a>
                    </p>

                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Landiewiese_-_M%C3%A4dchen_mit_erhobenen_H%C3%A4nden_%28Hermann_Haller%29_-_Landiwiese_-_Wollishofen_2012-03-12_13-50-24_%28P7000%29.JPG/800px-Landiewiese_-_M%C3%A4dchen_mit_erhobenen_H%C3%A4nden_%28Hermann_Haller%29_-_Landiwiese_-_Wollishofen_2012-03-12_13-50-24_%28P7000%29.JPG"
                        alt="Aufbau der Landi 1939"
                    />
                    <p className="image-caption">
                        Mädchen mit erhobenen Armen (Hermann Haller, 1939)
                        <br/>
                        Von <a href="//commons.wikimedia.org/wiki/User:Roland_zh" title="User:Roland zh">Roland
                        zh</a> - <span class="int-own-work" lang="de">Eigenes Werk</span>, <a
                        href="https://creativecommons.org/licenses/by-sa/3.0"
                        title="Creative Commons Attribution-Share Alike 3.0">CC BY-SA 3.0</a>, <a
                        href="https://commons.wikimedia.org/w/index.php?curid=18681247">Link</a>
                    </p>
                </div>
                <div className="text">
                    <h3>Skulpturen</h3>
                    <p>
                        Im Rahmen der Landi 39 wurden zahlreiche Wandgemälde und Skulpturen erschaffen, von denen viele
                        im Laufe der Zeit verschwunden sind, während andere – teils in Fragmenten – bis heute erhalten
                        geblieben sind.

                        Eine der markantesten Skulpturen war die überlebensgrosse Gipsskulptur „Knabe mit Pferd“, die
                        von Otto Charles Bänninger am linken Ufer des Sees aufgestellt wurde. Leider existieren von
                        dieser Figur nur noch Fotografien; der Pferdekörper wurde zerstört, während die Darstellung des
                        Knaben im Besitz der Stadt Zürich ist.


                        Die Steinskulptur „Schreitender Löwe“ von Karl Geiser, die ursprünglich während der Landi am
                        Aufgang zur Höhenstrasse in Wollishofen stand, ist heute vor dem kantonalen Verwaltungsgebäude
                        am Walcheplatz in Zürich zu finden.

                        Die 5,80 Meter hohe Skulptur „Wehrbereitschaft“ von Hans Brandenberger, die einen Mann
                        darstellt, der in seinen Kittel schlüpft und damit den Wehrwillen symbolisiert, war Teil der
                        Abteilung „Wehrwillen“ an der Höhenstrasse. Diese Gipsfigur ist jedoch inzwischen verschwunden.

                        Nach der Landi fertigte Brandenberger eine Kopie des „Wehrmanns“ aus Castione-Marmor an. Diese
                        ist einen Meter kleiner und weist einige Unterschiede in der Form auf. Sie steht heute an der
                        Ecke der Rämistrasse 80 in Zürich. Eine weitere Kopie aus Bronze, ein Abguss des Originals,
                        befindet sich im Bundesbriefmuseum in Schwyz. Diese wurde 1941 im Rahmen der 650-Jahr-Feier
                        den Auslandschweizern geschenkt.


                        Vor dem Anatomie-Pavillon war die Skulptur „Mutter und Kind“ des Bildhauers Franz Fischer
                        ausgestellt.

                        Heute befindet sich auf der Landiwiese die Bronzeskulptur „Mädchen mit erhobenen Armen“, die
                        der Zürcher Bildhauer Hermann Haller für die Landesausstellung 1939 aus Gips erschaffen hatte.
                        1968 wurde sie durch eine Schenkung der Haller-Erben in den Besitz der Stadt Zürich übergeben,
                        die sie anschliessend in Bronze goss. Über das Modell der Plastik gibt es unterschiedliche
                        Quellenangaben.
                        <br/>
                        <br/>


                    </p>
                </div>

            </div>
            <h3>Video</h3>

            <button onClick={toggleVideo} className="toggle-button">
                {isVideoVisible ? '↑ Video ausblenden' : '↓ Video anzeigen'}
            </button>



            {isVideoVisible && (
                <div className={`video-container ${isVideoVisible ? 'open' : ''}`}>
                    <iframe
                        src="https://www.youtube.com/embed/K2xMckCuYF0?si=JqRC-XHhJZqQb..."
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Landi 1939 Video"
                    ></iframe>
                </div>
            )}

        </section>
    );
}

export default Landi1939;
