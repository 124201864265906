import React, {useEffect} from "react";


export default function Gegenwartsbezug({ updateSources }) {
    useEffect(() => {
        const sources = [
            { text: "Historisches Lexikon der Schweiz", link: "https://hls-dhs-dss.ch/de/articles/017426/2006-11-23/" },
            { text: "Wikipedia: Geistige Landesverteidigung", link: "https://de.wikipedia.org/wiki/Geistige_Landesverteidigung" },
            { text: "Swissinfo", link: "https://www.swissinfo.ch/ger/politik/direkte-demokratie_die-geschichte-der-schweiz-als-eine-geschichte-der-ausgrenzung-tatsaechlich/45801518"}
        ];
        updateSources(sources);
    }, []);
    return (
        <section className="content">
            <h2>Gegenwartsbezug der Geistigen Landesverteidigung</h2>
            <div className="content-wrapper">
                <div className="text">
                    <p>Die Geistige Landesverteidigung der Schweiz, obwohl offiziell in den 1960er Jahren aufgegeben.
                        Doch man sieht trotzdem noch Spuren der geistigen Landesverteidigung.
                        Rechtsbürgerliche Parteien wie die SVP greifen weiterhin auf Ideen der Geistigen Landesverteidigung zurück,
                        insbesondere bei Themen wie EU-Integration und Einwanderung. Obwohl die Geistige Landesverteidigung als Kritisch gesehen wird,
                        sind trotzdem gewisse Aspekte in der Politik präsent. Vor allem bei der Schweizer Eigenständigkeit und Traditionen.
                        Traditionen.</p>
                    <p>Auch in der Bildung ist die Geistige Landesverteidigung noch sichtbar. Die direkte Demokratie und der Föderalismus wird in der Schule betont.</p>
                    <br/>
                    <p>Die Geistige Landesverteidigung wird zwar nicht mehr durchgeführt, doch man sieht die Spuren noch im Alltag. Das zeigt, wie gut es funktioniert hat und wie es unsere Gesellschaft geprägt hat.</p>
                </div>

                <div className="image-container">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/e/ea/Z%C3%BCrich_R%C3%A4mistr_Wehrbereitschaft.jpg"
                        alt="Bild von Hans Brandenberger"/>
                    <p className="image-caption">Marmorskulptur Wehrbereitschaft von Hans Brandenberger <br/>
                        1943–1947, das Original in Bronze <br/>
                        war ein Symbol der Landi 1939</p>
                </div>

                </div>
        </section>
    );
}

