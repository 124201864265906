import React, { useEffect } from 'react';
import bergfoto from "../assets/berge.jpg";

function Home({ updateSources }) {
    useEffect(() => {
        updateSources([]);
    }, []);
    return (
        <section className="content">
            <h2>Geistige Landesverteidigung – Die Schweiz zwischen Neutralität und Bedrohung im Zweiten Weltkrieg</h2>
            <p>
                Entdecken Sie die Rolle der Schweiz im Zweiten Weltkrieg – die Balance zwischen Neutralität und
                Bedrohung.
                Diese Website beleuchtet die historischen Hintergründe, die Entstehung und Umsetzung dieses
                einzigartigen
                Konzepts sowie den Einfluss auf die heutige Zeit.

                Tauche in unsere Themenbereiche ein, mehr über die Strategien der Schweiz in einer Ära globaler
                Unsicherheit zu lernen.
            </p>

            <div className="image-container-100">
                <img
                    src="https://www.sozialarchiv.ch/wp-content/uploads/fileadmin/user_upload/Sozialarchiv/Bilder/NEWS/Landi_1939.jpg"
                    alt="Logo Landi 1939"

                />
                <p className="image-caption">
                    Werbung für die Schweizer Landesausstellung 1939 <br/>
                    Von <a href="https://www.sozialarchiv.ch/2014/09/22/vor-75-jahren-der-satus-an-der-landi/">Sozialarchiv.ch</a>

                </p>
            </div>

        </section>
    );
}

export default Home;
