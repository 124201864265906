import React, { useEffect } from 'react';
import bergfoto from '../assets/berge.jpg';

function Effects({ updateSources }) {
    useEffect(() => {
        const sources = [
            { text: "Historisches Lexikon der Schweiz", link: "https://hls-dhs-dss.ch" },
            { text: "Wikipedia: Geistige Landesverteidigung", link: "https://de.wikipedia.org/wiki/Geistige_Landesverteidigung" }
        ];
        updateSources(sources);
    }, []);

    return (
        <section className="content">
            <div className="content-wrapper">
                <div className="text">
                    <h2>Auswirkungen der Geistigen Landesverteidigung</h2>
                    <p>
                        Die Geistige Landesverteidigung hatte von den 1930er bis in die 1960er Jahre einen grossen Einfluss
                        auf die Schweiz. Sie hat das Land kulturell, politisch und gesellschaftlich geprägt. Die Bewegung zielte
                        darauf ab, die nationale Identität der Schweiz zu stärken und eine gemeinsame Volksgemeinschaft zu
                        formen. Dabei ging es darum, die kulturelle Vielfalt und Mehrsprachigkeit der Schweiz zu betonen und die
                        Verbindung zu den drei wichtigen europäischen Kulturkreisen hervorzuheben (romanische, germanische und slawische).
                    </p>
                    <p>
                        Im politischen Bereich unterstützte die Geistige Landesverteidigung ein stärkeres Nationalbewusstsein
                        und einen bewaffneten Neutralitätsansatz, der heutzutage von grossem Interesse für die Schweiz ist.
                        Zugleich zeigte die Bewegung sämtliche totalitäre Strömungen – die NS, den Faschismus und dann den Kommunismus.
                        Diese Ziele wurden durch die Demokratisierung und die Achtung vor Schweizer Traditionen in Schweiz Rechnung getragen.
                    </p>
                    <p>
                        Kultur und Medien erlitten ebenfalls grosse Standortwechsel. Neue kulturelle Institutionen entstanden,
                        die die Schweizerisierung propagierten. Die schweizer Filmproduktion wurde gefördert, und es wurde
                        die Kulturstiftung Pro Helvetia ins Leben gerufen.
                    </p>
                    <p>
                        Viele Werte des Geistes der nationalen Verteidigung blieben auch nach ihrem offiziellen Abschluss
                        relevant. Sie halfen, die kulturellen Werte nach dem Krieg zu behalten. Sie förderten auch die
                        Zusammenarbeit zwischen Arbeit und Wirtschaft. Insgesamt stärkte der Geist der nationalen Verteidigung die
                        Schweizer Selbstidentität und nationale Konsolidierung während des Drangs von Bedrohungen.
                    </p>
                </div>
                <div className="image-container">
                    <img
                        src={bergfoto}
                        alt="Logo Landi 1939"

                    />
                    <p className="image-caption">
                        Die schweizer Berge im Wallis <br/>
                        Bild von Lorenz Held

                    </p>
                </div>
            </div>
        </section>
    );
}

export default Effects;
