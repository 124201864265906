import React from 'react';

function Footer({ sources }) {
    return (
        <footer className="footer">
            <p>© 2024, KBW - Kantonsschule Büelrain Winterthur</p>
            <p>Lorenz Held und Andrin Renggli</p>
            <p>Erstellungsdatum: 28.10.2024</p>
            <p>Letzte Änderung: 20.11.2024</p>
            {sources && sources.length > 0 && (
                <div>
                    <h3>Quellen:</h3>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        {sources.map((source, index) => (
                            <li key={index}><a href={source.link}>{source.text}</a></li>
                        ))}
                    </ul>
                </div>
            )}


        </footer>
    );
}

export default Footer;
